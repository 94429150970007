@import url('https://fonts.googleapis.com/css?family=Amatic+SC|Josefin+Slab:400,700');

$primary: #ed2793; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: $primary;
html,body {
  height: 100%;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-family: 'Josefin Slab', serif;
  font-size: 1.8em;
  @media(max-width: 767px){
    font-size: 1.5em;
  }
  @media(max-width:600px){
    font-size: 1.3em;
  }
  color:$blk;


}

.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
				  margin-top: 0;
				  padding: 6px 0px;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 2em 0em;
	transition: 0.7s ease all;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		text-decoration: none;

			@media (max-width: 1024px) {
				background: $secondary;
				color: $wht;
				box-shadow: 0px 0px 5px $blk;
			}
	}
}

.btn-default {
	@include btn;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 100%;
		font-size: 18px;
    	margin: 0 auto;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/
// sectino -start
section{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
}
.section-a {
  padding: 400px 0px;
  background-color: $primary;
  background-image: url('../img/bg1.jpg');
  background-position: top;
  background-attachment: fixed;
  @media(max-width: 1024px){
    background-attachment: scroll;
    padding: 200px 0;
  }
  @media(max-width: 767px){
    background-attachment: scroll;
    padding: 130px 0;
    background-position: right;
  }
}
.section-b {
  padding: 80px 0;
  background-color:$primary;
  color: $wht;
  img {
    border: 10px solid rgba(0, 0, 0, .3);
    @media(max-width: 991px){
      max-width: 350px;
      margin-bottom: 25px;
    }
    @media(max-width:767px){
      max-width: 200px;
      margin-bottom: 25px;
    }
  }
  h1 {
    font-size: 3em;
    margin-bottom: 45px;
    @media(max-width:767px){
      margin-bottom: 25px;
      font-size: 2em;
    }
    @media(max-width:600px){
      margin-bottom: 15px;
      font-size: 2em;
    }
  }
}
.section-c{
  h1{
    font-size: 2.7em;
    line-height: 2em;
    @media(max-width:991px){
      font-size: 2em;
    }
    @media(max-width:767px){
      font-size: 1.5em;
    }
  }
}
.section-d {
  padding: 80px 0;
  background-color:$primary;
  img {
    border: 10px solid rgba(0, 0, 0, .3);
  }
  p{
    color: $wht;
    font-size: 1.2em;
    text-align: center;
    margin-top: 25px;
  }
}
.section-e {
  p {
    margin-bottom: 45px;
    @media(max-width:767px){
      margin-bottom: 25px
    }
    @media(max-width:600px){
      margin-bottom: 15px
    }
  }
  img {
    border: 10px solid rgba(0, 0, 0, .3);
    border-radius: 300px;
    @media(max-width: 991px){
      max-width: 350px;
      margin-bottom: 25px;
    }
    @media(max-width:767px){
      max-width: 200px;
      margin-bottom: 25px;
    }
  }
  a {
    color: $blk;
    text-decoration: none;
  }

  .text-member {
    text-align: center;
    text-decoration: none;color: $blk;
    padding-top: 25px;
    @media(max-width:991px){
      padding-top: 0px;
      padding-bottom: 35px;
    }
  }
}
footer {
	padding: 50px 0px 20px;
	background: $wht;
	color: $footerLinks;
	font-size: 15px;
	a {
		color: $footerLinks;
    white-space: nowrap;
    font-weight: bold;
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}

		&:hover{
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $primary;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;
    font-weight: bold;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}


input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}





select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
  .logo {
    max-width: 125px;
  }

  .navbar-toggle {
    margin-top: 5px;
  }

}


.top-pad {
	padding: 3em;
	background: white;
}


.flex {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1024px) {
		display: block;  /* may need to be inline-block */
	}
}
